import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {AiOutlineLogout} from "react-icons/ai"
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import './dashboard.css'

const Photos = () => {
  const [token, setToken] = useState();
  const [imagedata, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState()
  const navigate = useNavigate();
  const toggle2 = JSON.parse(localStorage.getItem('boolean'));

  const url = process.env.REACT_APP_API_KEY
  
  useEffect(() => {
    // Define a function to handle storage events
    const handleStorage = (event) => {
      // Check if the event key is "token" and update the state
      if (event.key === "token") {
        setToken(JSON.parse(event.newValue));
      }
    };

    // Read the initial token from localStorage
    const Token = JSON.parse(localStorage.getItem("token"));
    setToken(Token);

    // Add an event listener for storage changes
    window.addEventListener("storage", handleStorage);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  useEffect(() => {
    // Only make the API call if there is a token
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${token}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`${url}/api/user/user_details/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setLoading(true);
          setImageData(result.images)
          
        })
        .catch((error) => 
        console.log("error", error),
        );
    }
  }, [token]); // Use token as a dependency




  return (
<>
<Navbar/>
<div id={imagedata?.length >=5 ? "main_flex" : "main_flex_for2"} className="main_flex">
<Sidebar/>
<div style={{height:"auto",width:"100%"}} className={toggle2 ? "toggle": "" }>
    <div className="dashboardheightformobile">
    {loading ? <>
      <div className={imagedata.length>0 ? "" : "nomatchedphotos"} id="dashboardheightfix">
      <div className="buttonDiv" style={{display:"flex",justifyContent:"end",alignItems:"center"}}>
  
        </div>
        {imagedata.length ==0 ? <h1 style={{textAlign:"center"}} id="nomatchPhotomobile">No Photos Found</h1> : <h1 id="nomatchPhotomobile" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>Matched Images</h1> }
      
      <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
          borderRadius: "10px",
          padding:"10px"
        }}>
          

      {imagedata?.map((val, index) => {
              return (
                <div key={index} className="imageandlinkdiv">
                  <div key={index}className="thumbnail-img">
                  <a
                          href={val?.image_url}
                          style={{ textDecoration: "none", color: "white" }}
                          target="_blank" rel="noreferrer"
                        >
                           <img
                      style={{
                        height: "200px",
                        width: "auto",
                        marginTop: "30px",
                        borderRadius: "10px",
                        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"
                      }}
                      src={val.thumbnail_url}
                      alt="drive"
                    />
                        </a>
                   
                    <div style={{textAlign: "center"}}>

                    {/* <button
                        key={index}
                        style={{
                          background: "black",
                          borderRadius: "999px",
                         
                          boxSizing: "border-box",
                          color: "#FFFFFF",
                          cursor: "pointer",
                          fontFamily: "Inter,Helvetica,Apple Color Emoji",
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "35px",
                          opacity: "1",
                          outline: "0 solid transparent",
                          padding: "8px 18px",
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          touchAction: "manipulation",
                          width: "fit-content",
                          wordBreak: "break-word",
                          border: "0",
                          marginTop: "20px",
                        }}
                      >
                        <a
                          href={val?.image_url}
                          style={{ textDecoration: "none", color: "white" }}
                          target="_blank" rel="noreferrer"
                        >
                          LINK
                        </a>
                      </button> */}
                    </div>
                      
                    {/* <div style={{ textAlign: "center", marginBottom: "100px" }}>
                    </div> */}
                  </div>
                  
                </div>
              );
            })}
      </div>
            
    </div>
    </> : 
    
    <div className="photos-loader">
    {token ? 
    <>
    <img style={{height:"200px",width:"200px"}} src="https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif"  alt="Loading..."/>
    <br/>
    <h1 style={{textAlign:"center"}}>Getting your Photos ❗</h1>
    </>:<></>}
    </div>
    }

</div>
</div>
</div>
</>
  );
};

export default Photos;
