import React, {useState} from 'react';
import './Sidebar.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import {IoMdPhotos} from "react-icons/io"
import {FiLoader} from "react-icons/fi"
import Dashboard from './Dashboard';
import './navbar-media.css';


function Sidebar() {
  const [on,setOn] = useState(JSON.parse(localStorage.getItem('boolean')));
  const handleClick = () =>{
    setOn(!on);
    localStorage.setItem('boolean',on);
  }
  const toggle = localStorage.getItem('boolean');

  
  if(toggle == null){
    localStorage.setItem('boolean',false);
  }
  let value = JSON.parse(localStorage.getItem('boolean'))
  
  return (
    // <Router>
    <>
    
    <div className="containerr">
      <div className={value ? 'Sidebar' : 'sidebar'}>
        <ul>
          <li>
          <Link to="/photos" onClick={handleClick}><span id='sidebarphotos'><IoMdPhotos/></span>PHOTOS</Link>
          </li>
          <hr/>
          <li>
          <Link to="/dashboard" onClick={handleClick}><span id='sidebarscanphotos'><FiLoader/></span>SCANNING FOR PHOTOS</Link>
          </li>
        </ul>
      </div>
      
    </div>
    </>
  );
}

export default Sidebar;
