import React from 'react'
import { Navigate } from 'react-router'

export const PrivateRoutes = ({children}) => {
    const token = JSON.parse(localStorage.getItem('token'))
    if(!token){
        return <Navigate to='/login' />
    }
  return (
    children
  )
}
