import React from 'react'
import { Route, Routes } from 'react-router'
import { CustomRoutes } from './CustomRoutes'
import Signup from '../Components/Signup'
import Login from '../Components/Login'
import Dashboard from '../Components/Dashboard'
import  Photos  from '../Components/Photos'
import { PrivateRoutes } from '../Components/PrivateRoutes'



const Croutes = () => {
  return (
    <Routes>
            <Route path={CustomRoutes.BASE_URL} element={<Signup/>} />
            <Route path={CustomRoutes.SIGNUP} element={<Signup/>} />
            <Route path={CustomRoutes.LOGIN} element={<Login/>} />
            <Route path={CustomRoutes.DASHBOARD} element={<PrivateRoutes><Dashboard /></PrivateRoutes>} />
            <Route path={CustomRoutes.PHOTOS} element= {<PrivateRoutes><Photos/></PrivateRoutes>} />
        </Routes>
  )
}
export default Croutes
