import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBInput,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "./Signup.css"
import axios from "axios";
import "./login-media.css";

const Login = () => {
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const url = process.env.REACT_APP_API_KEY;

  const navigate = useNavigate();
  localStorage.clear();

  const handleClick = (e) => {
    e.preventDefault();
    const formData = new FormData();
    setLoader(true);
    formData.append("email", formValue.email);
    formData.append("password", formValue.password);
    setLoader(true);

    axios
      .post(`${url}/api/user/login/`, formData)
      .then((res) => {
        setLoader(false);
        if (res.data.message) {
          if (res.data.Token) {
            alert(res.data.message);
            localStorage.setItem("token", JSON.stringify(res.data.Token));
            navigate("/photos");
          } else {
            alert(res.data.message);
            localStorage.removeItem("token");
          }
          // console.log(res.data.Token);
          return;
        }
        if (res.data) {
          alert(res.data);
          return;
        }
        console.log(res.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  return (
    <>
      <form onSubmit={handleClick}>
        <MDBContainer
          fluid
          className="d-flex align-items-center justify-content-center bg-image"
          id="containerLogin"
        > 
          <MDBCard className="m-5" id="main-login-card">
            <MDBCardBody className="paddingBody" id="cardBodyLogin">
              <h2 className="text-uppercase text-center " id="loginText">
                LOGIN
              </h2>
              <MDBInput
                wrapperClass="mb-4"
                label="Your Email"
                size="lg"
                id="form2"
                type="email"
                name="email"
                onChange={onChange}
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label="Password"
                size="lg"
                id="form3"
                type="password"
                name="password"
                onChange={onChange}
                required
              />
              <MDBBtn
                type="submit"
                className="mb-4 w-100 gradient-custom-4"
                size="lg"
                style={{ background: "black" }}
                required
              >
                {loader ? (
              <div style={{textAlign: "center"}}> 
                <img
                style={{height:"20px",width:"20px"}}
                  src="https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif"
                  alt="Loading..."
                />
              </div>
            ) : (
              <>Login</>
            )}
              </MDBBtn>
              <div className="loginbottomtext">
                <p className="loginPara" style={{ textAlign: "center" }}>
                  Don't have an account?
                  <Link
                    to="/signup"
                    className="fw-bold"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    Signup
                  </Link>
                </p>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </form>
    </>
  );
};

export default Login;
