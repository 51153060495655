import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import "./navbar-media.css";
import logo from "../assets/photos/logo.jpeg";

const Navbar = () => {
  const [on, setOn] = useState(
    JSON.parse(localStorage.getItem("boolean")) || true
  );
  const navigate = useNavigate();
  const handleClassName = () => {
    setOn(!on);
    localStorage.setItem("boolean", on);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark" id="navhead">
      <div className="container-fluid">
        <h1>
          <Link className="nav-link active" aria-current="page">
            <span className="span-logo">
              <img className="nav-logo" src={logo} alt="logo" />
            </span>
            <span>
              <GiHamburgerMenu onClick={handleClassName} id="hamburger" />
            </span>
            <span id="dashboard">MITRA STUDIO</span>
          </Link>
        </h1>
        <div className="MitraStudioMobile">
        
          <h4>MITRA STUDIO</h4>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              {/* {/ <Link className="nav-link active" aria-current="page" to="/mainlayout">Home</Link> /} */}
            </li>
          </ul>
          <>
            {" "}
            <button
              style={{
                background: "black",
                borderRadius: "12px",

                boxSizing: "border-box",
                color: "white",
                cursor: "pointer",
                fontFamily: "Inter,Helvetica,Apple Color Emoji",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "35px",
                opacity: "1",
                outline: "0 solid transparent",
                padding: "8px 18px",
                userSelect: "none",
                WebkitUserSelect: "none",
                touchAction: "manipulation",
                width: "fit-content",
                wordBreak: "break-word",
                border: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "5px 10px",
              }}
              onClick={handleLogout}
            >
              <AiOutlineLogout style={{ height: "10%", color: "red" }} />
            </button>
          </>
          {/* <form className="d-flex">
          <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
