import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Webcam from 'react-webcam';
import { Link, useNavigate } from "react-router-dom";
import "./media-query.css";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import axios from "axios";

function Signup() {
  const webcamRef = useRef(null);
  
  const [errorMessage, setErrorMessage] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [imageData, setImageData] = useState();
  const [captureImage,setCaptureImage] = useState();

  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword:""
  });
  const [sentImage, setImageSent] = useState();
  const validatePassword = () => {
    // Get the values of the password and confirm password fields
    const password = formValue.password;
    const confirmPassword = formValue.confirmpassword;
    // Compare the values and set the error message accordingly
    if (password !== confirmPassword) {
      setErrorMessage("Password does not match!🤨");
      setFormValue({...formValue,password:"",confirmpassword:""});
      return
    } else {
      setErrorMessage("");
    }
  };
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  
  useEffect(()=>{
     localStorage.clear();

  },[])

  // localStorage.clear();
  const url = process.env.REACT_APP_API_KEY
  

  const handleClick = (e) => {
    e.preventDefault();
    if(sentImage==undefined || formValue.name==""){
      alert("Please fill all fields")
      return
    }
    const formData = new FormData();

    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append("password", formValue.password);
    if(sentImage){
      
      formData.append("photo", imageData);
    }else{
      formData.append("photo", captureImage);
    }
    
    setLoader(true);
    axios
      .post(
        `${url}/api/user/register/`,
        formData
      )
      .then((res) => {
        if (res.data.message) {
          alert(res.data.message);
          setLoader(false);
          if (res.data.Token) {
            localStorage.setItem("token", JSON.stringify(res.data.Token));
            setLoader(true);
            encode_Computing();
          }
        }else{
          setLoader(false);
          alert(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        alert("Oops! Something went wrong 😥")
        console.log(err);
      });
  };

  const encode_Computing = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    );

    const formdata = new FormData();
    formdata.append(
      "photo",
      sentImage ? imageData : captureImage
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${url}/api/user/compute_encodings/`,
      requestOptions
    )
    .then((response) => response.text())
    .then((data) => {
      setLoader(false);
      console.log(data);
      navigate("/dashboard")
    })
    .catch((error) => console.log("error", error));
  };
  const capture = (e) => {
    e.preventDefault();
    let imageSrc = webcamRef.current.getScreenshot();
  
    // Remove data URI scheme if it exists
    if (imageSrc.includes('data:image/jpeg;base64,')) {
      imageSrc = imageSrc.replace('data:image/jpeg;base64,', '');
    }
  
    let byteString = atob(imageSrc);
    let mimeString = 'image/jpeg';
  
    let arrayBuffer = new ArrayBuffer(byteString.length);
    let _ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }
  
    let blob = new Blob([_ia], { type: mimeString });
    var file = new File([blob], "filename.jpg", {type: "image/jpeg", lastModified: Date.now()});
  
    setCaptureImage(file);
    setShowCamera(false);
  }
  
  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  return (
    <div
    className="main">
    <div className="notediv" style={{ marginRight: "10px" }}>
      <h1>Note*</h1>
      <ul>
        <li className="choose-file">Choose file or Open Camera to upload the photo!</li>
        <li>Face should be clear in the Uploaded Image</li>
        <li>Make sure to remove your specs/glasses</li>
        <li>Avoid photos which has Glare</li>
      </ul>
    </div>

    <div>
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center bg-image"
        style={{ borderRadius: "10px" }}
      >
        {/* {/ <div className="mask gradient-custom-3"></div> /} */}
        <MDBCard className="m-md-5" style={{ maxWidth: "600px" }}>
          <MDBCardBody id="signup-card">
            <h2 className="text-uppercase text-center">
              Create an account
            </h2>
            <MDBInput
              wrapperClass="mb-4"
              label="Your Name"
              size="lg"
              type="text"
              value={formValue.name}
              name="name"
              onChange={onChange}
              id="form1"
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Your Email"
              size="lg"
              id="form2"
              type="email"
              value={formValue.email}
              name="email"
              onChange={onChange}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              size="lg"
              id="form3"
              type="password"
              value={formValue.password}
              name="password"
              onChange={onChange}
              required
            />
              <MDBInput
              wrapperClass="mb-4"
              label="Confirm Password"
              size="lg"
              id="form3"
              type="password"
              value={formValue.confirmpassword}
              name="confirmpassword"
              onChange={onChange}
              onBlur={validatePassword}
              required
            />
            {errorMessage && <p className="text-danger" id="errormessage">{errorMessage}</p>}
            <MDBInput
              wrapperClass="mb-4"
              size="lg"
              id="form4"
              type="file"
              value={formValue.photo}
              name="photo"
              onChange={(e) => {
                setImageSent(true)
                setImageData(e.target.files[0]);
              }}
              
            />
           <MDBBtn className="mb-4 w-100 gradient-custom-4"
           id="button-for-open-camera"
              size="lg"
              style={{ background: "black" }} onClick={() => {
                setShowCamera(true)
                setImageSent(false);
                }}>Open Camera</MDBBtn>
            <div className="popup-wrapper">
            {showCamera && (
        <div className="camera-popup">
          <button className="close-button" onClick={() => setShowCamera(false)}>X</button>
          <Webcam
          id="webcam"
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
          <button className="capture-btn" onClick={capture}>Capture photo</button>
        </div>)}
            </div>
  
            <MDBBtn
              onClick={handleClick}
              className="mb-4 w-100 gradient-custom-4"
              size="lg"
              style={{ background: "black" }}
              required
            >
          {loader ? (
            <div style={{textAlign: "center"}}> 
              <img
              style={{height:"20px",width:"20px"}}
                src="https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif"
                alt="Loading..."
              />
            </div>
          ) : (
            <>Register</>
          )}
            </MDBBtn>
            <div>
              <p className="mb-0" style={{ textAlign: "center" }}>
                Already have an account? {" "}
                <Link
                  to="/login"
                  className="fw-bold"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Login
                </Link>
              </p>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  </div>
  );
}

export default Signup;
