import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes } from 'react-router-dom';
import Croutes from './CusRoutes/Croutes';

function App() {
  return (
   <BrowserRouter>
      <Croutes/>
   </BrowserRouter>
  );
}

export default App;
