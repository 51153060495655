import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {AiOutlineLogout} from "react-icons/ai";
import "./dashboard.css"

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Dashboard = () => {
  const [token, setToken] = useState();
  const [imagedata, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState()
  const navigate = useNavigate();
  const toggle2 = JSON.parse(localStorage.getItem('boolean'));
  const url = process.env.REACT_APP_API_KEY

  useEffect(() => {
    // Define a function to handle storage events
    const handleStorage = (event) => {
      // Check if the event key is "token" and update the state
      if (event.key === "token") {
        setToken(JSON.parse(event.newValue));
      }
    };

    // Read the initial token from localStorage
    const Token = JSON.parse(localStorage.getItem("token"));
    setToken(Token);

    // Add an event listener for storage changes
    window.addEventListener("storage", handleStorage);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  useEffect(() => {
    // Only make the API call if there is a token
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${token}`
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`${url}/get_photo/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoading(true);
          if(result.Message){
            alert(result.Message);
            setMessage(result.Message);
            return
          }
          
          console.log(result);
          setImageData(result.matched_faces)
        })
        .catch((error) => 
        console.log("error", error),
        );
    }
  }, [token]); // Use token as a dependency



  return (
<>
<Navbar/>
<div style={{display:"flex"}}>
<Sidebar/>
<div style={{height:"auto",width:"100%"}} className={toggle2 ? "toggle": "" }>
    <div className="dashboardheightformobile">
    {loading ? <>
      <div>
      <div className="buttonDiv" style={{display:"flex",justifyContent:"end",alignItems:"center"}}>
        
        </div>
        {message ? <h1>No Matched Photos</h1> : <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>Matched Images</h1> }
      
      <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
          borderRadius: "10px",
        }}>

      {imagedata?.map((val, index) => {
              return (
                <div key={index}>
                  <div key={index}>
                    <img
                      style={{
                        height: "200px",
                        width: "200px",
                        marginTop: "30px",
                        borderRadius: "10px",
                      }}
                      src={val.thumbnailLink}
                      alt="drive"
                    />

                    <div style={{ textAlign: "center", marginBottom: "100px" }}>
                      <button
                        key={index}
                        style={{
                          background: "#5E5DF0",
                          borderRadius: "999px",
                          boxShadow: "#5E5DF0 0 10px 20px -10px",
                          boxSizing: "border-box",
                          color: "#FFFFFF",
                          cursor: "pointer",
                          fontFamily: "Inter,Helvetica,Apple Color Emoji",
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "35px",
                          opacity: "1",
                          outline: "0 solid transparent",
                          padding: "8px 18px",
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          touchAction: "manipulation",
                          width: "fit-content",
                          wordBreak: "break-word",
                          border: "0",
                          marginTop: "20px",
                        }}
                      >
                        <a
                          href={val?.file_url}
                          style={{ textDecoration: "none", color: "black" }}
                          target="_blank" rel="noreferrer"
                        >
                          LINK
                        </a>
                      </button>
                    </div>
                  </div>
                  
                </div>
              );
            })}
      </div>
            
    </div>
    </> : 
    
    <div className="loader" style={{textAlign:"center"}}>
      
    {token ? 
    <>
    <img style={{height:"200px",width:"200px"}} src="https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif"  alt="Loading..."/>
    <br/>
    <h1 style={{textAlign:"center"}}>You will receive a mail when your photos are ready. Please check the Photos tab after you receive a mail.</h1>
    </>:<></>}
    </div>
    }

</div>
</div>
</div>
</>
  );
};

export default Dashboard;
